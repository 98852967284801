import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';

@Injectable()
export class ConfigService {
    static settings: any;

    constructor(private http: HttpClient) { }
    load() {
        const jsonFile = 'config.json';
        return new Promise<void>((resolve, reject) => {
            lastValueFrom(this.http.get(jsonFile)).then((response: any) => {
                ConfigService.settings = <any>response;
                resolve();
            }).catch((response: any) => {
                reject(`Could not load file '${jsonFile}': ${JSON.stringify(response)}`);
            });
        });
    }
}