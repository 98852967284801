import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IPerson, IUser } from 'froitzheim-shared';
import { validateIBAN } from 'ngx-iban-validator';

@Component({
  selector: 'app-bank-input',
  templateUrl: './bank-input.component.html',
  styleUrls: ['./bank-input.component.scss'],
})
export class BankInputComponent implements OnInit {
  @Input() person!: IPerson;
  @Input() collapsed = false;

  form = new FormGroup({
    iban: new FormControl(''), //, Validators.minLength(2)),
    bic: new FormControl(''), //, Validators.minLength(2))
  });

  constructor() { }

  ngOnInit(): void {
    this.form.patchValue(this.person);
    this.form.valueChanges.subscribe(x => {
      this.person.iban = x.iban;
      this.person.bic = x.bic;
    });

   }
}
