import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { PanelModule } from 'primeng/panel';
import { TabViewModule } from 'primeng/tabview';
import { PersonalInfoFormComponent } from './personal-info-form.component';
import { AutoCompleteModule } from 'primeng/autocomplete';

@NgModule({
  declarations: [
    PersonalInfoFormComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    InputTextModule,
    TabViewModule,
    PanelModule,
    TranslateModule,
    DropdownModule,
    CalendarModule,
    AutoCompleteModule,
    ReactiveFormsModule,
  ],
  exports: [
    PersonalInfoFormComponent,
  ]
})
export class PersonalInfoFormModule { }