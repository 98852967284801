import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IForm, IFile } from 'froitzheim-shared';
import config from '../config.json';
import { Service } from './base.service';

@Injectable()
export class FormService extends Service<IForm> {
    constructor(override http: HttpClient) {
        super();
    }

    override readonly path = '/form';

    uploadFile(file: File) {
        const formData: FormData = new FormData();
        formData.append('file', file, file.name);
        return this.http.post<{path: string}>(`${this.getUrl()}/upload/${file.name}`, formData, this.getAuthHeaders());
    }

    deleteFile(id: number, file: IFile) {
        return this.http.delete(`${this.getUrl()}/${id}/download/${file.fileName}`, this.getAuthHeaders());
    }
}
