<form #personForm="ngForm" class="formgrid grid" [formGroup]="form">
  <div class="field col-12 md:col-6">
    <label for="firstname" class="block">Vorname</label>
    <input
      id="firstName"
      name="firstName"
      type="text"
      formControlName="firstName"
      pInputText
      [required]="true"
      minlength="2"
      appForbiddenValue="tes"
      class="w-full" />
  </div>
  <div class="field col-12 md:col-6">
    <label for="lastname">Nachname</label>
    <input
      name="lastname"
      type="text"
      class="w-full"
      formControlName="name"
      [required]="true"
      minlength="2"
      pInputText />
  </div>
  <div div class="field col-12 md:col-3">
    <label for="gender">Geschlecht</label>
    <p-dropdown
      name="gender"
      inputId="gender"
      [options]="genders"
      formControlName="gender"
      [optionValue]="'key'"
      styleClass="w-full"
      appendTo="body">
      <ng-template let-gender pTemplate="selectedItem">
        <p>
          {{ "gender." + gender.value | lowercase | translate }}
        </p>
      </ng-template>
      <ng-template let-gender pTemplate="item">
        <p>
          {{ "gender." + gender.value | lowercase | translate }}
        </p>
      </ng-template>
    </p-dropdown>
  </div>
  <div div class="field col-12 md:col-3">
    <label for="familyState">Familienstand</label>
    <p-dropdown
      name="familyState"
      inputId="familyState"
      [options]="familyStates"
      formControlName="familyStatus"
      [optionValue]="'key'"
      styleClass="w-full"
      appendTo="body">
      <ng-template let-familyStatus pTemplate="selectedItem">
        <p>
          {{ "family." + familyStatus.value | lowercase | translate }}
        </p>
      </ng-template>
      <ng-template let-familyStatus pTemplate="item">
        <p>
          {{ "family." + familyStatus.value | lowercase | translate }}
        </p>
      </ng-template>
    </p-dropdown>
  </div>
  <div class="field col-12 md:col-6">
    <label for="birthday">Geburtsdatum</label>
    <p-calendar
      name="birthday"
      inputId="birthday"
      formControlName="birthday"
      [minDate]="minDate"
      [maxDate]="maxDate"
      placeholder="TT.MM.JJJJ"
      dateFormat="dd.mm.yy"
      styleClass=" w-full"></p-calendar>
  </div>
  <div class="field col-12 md:col-6">
    <label for="phone">Telefonnummer</label>
    <input
      name="phone"
      type="tel"
      placeholder="+491701234567"
      class="inputfield w-full"
      formControlName="phone"
      pInputText />
  </div>
  <div class="field col-12 md:col-6">
    <label for="email">E-Mail</label>
    <input
      name="email"
      type="email"
      email="true"
      size="30"
      class="inputfield w-full"
      formControlName="email"
      pInputText />
  </div>
  <!-- <div class="field col-12 address-search">
    <div class="container">
      <label for="address">Adresssuche</label>
      <p-autoComplete
        name="addressSearch"
        [(ngModel)]="address"
        [suggestions]="results"
        (completeMethod)="search($event.query)"
        (onSelect)="fillForm($event)"
        class="block"
        [inputStyle]="{ width: '100%' }"
        [style]="{ width: '100%' }">
        <ng-template let-result pTemplate="selectedItem">
          {{ result.properties.street }}
          {{ result.properties.housenumber }}, {{ result.properties.city }}
          {{ result.properties.postcode }}
        </ng-template>
        <ng-template let-result pTemplate="item">
          {{ result.properties.street }}
          {{ result.properties.housenumber }}, {{ result.properties.city }}
          {{ result.properties.postcode }}
        </ng-template>
      </p-autoComplete>
    </div>
  </div> -->
  <div class="field col-9">
    <label for="address">Straße</label>
    <input
      name="address"
      type="text"
      rows="4"
      formControlName="street"
      class="w-full"
      [required]="true"
      minlength="2"
      pInputText />
  </div>
  <div class="field col-3">
    <label for="addressNumber">Hausnummer</label>
    <input
      name="addressNumber"
      type="text"
      rows="4"
      formControlName="streetNumber"
      class="w-full"
      [required]="true"
      minlength="1"
      pInputText />
  </div>
  <div class="field col-12 md:col-4">
    <label for="zip">PLZ</label>
    <input
      name="zip"
      type="text"
      class="w-full"
      formControlName="postcode"
      [required]="true"
      minlength="4"
      pInputText />
  </div>
  <div class="field col-12 md:col-8">
    <label for="city">Stadt</label>
    <input
      name="city"
      type="text"
      class="w-full"
      formControlName="city"
      [required]="true"
      minlength="2"
      pInputText />
  </div>
</form>
