import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IPerson, ITerm, IUser, TermType } from 'froitzheim-shared';
import config from '../config.json';
import { Service } from './base.service';
import { ConfigService } from './config.service';

@Injectable()
export class TermService extends Service<ITerm> {
  constructor(override http: HttpClient) {
    super();
  }

  public getAllByType(type: TermType): Observable<ITerm[]> {
    return this.http.get<ITerm[]>(`${this.getUrl()}/type/${type}`);
  }

  public agreeUser(id: number, user: number): Observable<ITerm[]> {
    return this.http.post<ITerm[]>(`${this.getUrl()}/user/${id}/${user}`, {});
  }

  public agreeReferer(id: number, user: number): Observable<ITerm[]> {
    return this.http.post<ITerm[]>(`${this.getUrl()}/referer/${id}/${user}`, {});
  }

  public agreePerson(id: number, user: number): Observable<ITerm[]> {
    return this.http.post<ITerm[]>(`${this.getUrl()}/person/${id}/${user}`, {});
  }

  override readonly path = '/term';
}
