<div class="surface-ground px-4 py-5 md:px-6 lg:px-8">
  <div class="grid max-h-screen">
    <div class="col-12">
      <div *ngIf="employee">
        <!-- <p-breadcrumb [model]="items"></p-breadcrumb> -->
        <div class="text-3xl font-medium text-900">{{ title }}</div>
        <!-- <div class="mb-3" *ngIf="p_delete">
          <p-button
            label="Löschen"
            styleClass="p-button-rounded p-button-danger"
            (onClick)="delete($event)"></p-button>
        </div> -->

        <div #inheritance>
          <!-- <div *ngIf="parent" class="mb-3">
            <span>Geworben von </span>
            <a [routerLink]="['/', 'admin', 'employees', parent.user_id]"
              >{{ parent.firstName }} {{ parent.name }}</a
            >
          </div> -->
          <div *ngIf="employee?.children?.length" class="col-12 mb-3">
            Hat {{ countDescendants() }} Tippgeber geworben, davon wurden
            {{ employee!.children.length }} direkt geworben.
            <p-organizationChart class="mt-3" [value]="tree">
              <ng-template let-node pTemplate="url">
                <div>
                  {{ node.label }}
                  <div *ngIf="node.parent.user_id === employee!.user_id">
                    <label class="block my-2">
                      Provision
                      {{ node.data.referer.provision | percent }}
                      ({{ provision * node.data.referer.provision }}€)
                    </label>
                    <p-slider
                      *ngIf="p_update"
                      [(ngModel)]="node.data.referer.provision"
                      [min]="0"
                      [max]="1"
                      [step]="0.1"></p-slider>
                  </div>
                </div>
              </ng-template>
            </p-organizationChart>
          </div>
        </div>

        <div *ngIf="employee?.applications?.length" class="col-12 mb-3 flex flex-column align-items-start">
          Hat {{ employee!.applications!.length }} Anträge geworben.
          <button
            pButton
            [routerLink]="['/', 'overview', 'employees', employee.user_id, 'applications']"
            label="Anträge öffnen"></button>
        </div>

        <div class="formgrid grid" *ngIf="false">
          <div class="col-12">
            <h3>Kunden oder Tippgeber werben</h3>
          </div>
          <div class="field col-12 flex flex-column">
            <span class="p-input-icon-right">
              <div class="grid">
                <div class="col-6">
                  <button
                    pButton
                    (click)="copyReferal()"
                    label="Werbelink für Kunden kopieren"
                    style="width: 100%"></button>
                </div>
                <div class="col-6">
                  <button
                    pButton
                    label="Werbelink für Tippgeber kopieren"
                    (click)="copyRefererReferal()"
                    style="width: 100%"></button>
                </div>
              </div>
              <div
                class="referal-overlay"
                (click)="generateReferal()"
                *ngIf="!employee?.referal"
                style="top: -8px">
                <span> Werbelinks für diesen Tippgeber aktivieren? </span>
              </div>
            </span>
          </div>
        </div>
        <p-divider *ngIf="false"></p-divider>

        <h3>Daten und Einstellungen</h3>
        <div class="surface-card shadow-2 border-round">
          <p-tabView>
            <p-tabPanel header="Persönliche Informationen">
              <ng-container
                [ngTemplateOutlet]="information"
                [ngTemplateOutletContext]="{
                  $implicit: employee
                }"></ng-container>
            </p-tabPanel>
            <p-tabPanel *ngIf="p_read" header="Bankverbindung">
              <ng-container
                [ngTemplateOutlet]="bank"
                [ngTemplateOutletContext]="{
                  $implicit: employee
                }"></ng-container>
            </p-tabPanel>
            <p-tabPanel
              header="Mitarbeiterkennung"
              *ngIf="p_read && employee.broker_identifier">
              <ng-container
                [ngTemplateOutlet]="broker"
                [ngTemplateOutletContext]="{
                  $implicit: employee
                }"></ng-container>
            </p-tabPanel>
            <p-tabPanel *ngIf="p_read" header="Rollen">
              <ng-container
                [ngTemplateOutlet]="rolesRef"
                [ngTemplateOutletContext]="{
                  $implicit: employee
                }"></ng-container>
            </p-tabPanel>
          </p-tabView>
        </div>

        <p-blockUI [blocked]="uiBlock"></p-blockUI>
        <div class="mt-2">
          <p-button
            *ngIf="p_update"
            label="Speichern"
            (onClick)="submit()"></p-button>
        </div>
        <p-divider></p-divider>

        <div class="formgrid grid" *ngIf="p_update">
          <div class="col-12">
            <h3>Wiederherstellungslink per E-Mail zusenden</h3>
            <p class="block-text">
              Es kann passieren, dass Personen ihr Passwort nicht mehr
              kennen, oder Daten zu ihrem Benutzer ändern möchten. Sie
              können hier der Person einen Link zu der hinterlegten E-Mail
              zusenden, wodurch diese eigenständig Änderungen an dem
              Benutzer machen kann.
            </p>
            <p-button
              label="Bearbeitungs-Link zusenden"
              (onClick)="invite()"></p-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #rolesRef let-employee>
  <p class="block-text">
    Sie können der Person eine oder mehrere Rollen zuweisen. Rollen können
    standardmäßig zugewiesen sein, oder auch vom Werber weitergereicht
    werden. Diese Rollen sind speziell gekennzeichnet, können aber einfach
    überschrieben werden.
  </p>
  <div *ngFor="let role of roles" class="flex align-items-center gap-1">
    <p-checkbox
      name="roles"
      [value]="role.role_id"
      [(ngModel)]="selectedRoles"
      [inputId]="$any(role.role_id)"></p-checkbox>
    <label class="ml-2" [for]="role.role_id">{{ role.title }}</label>
    <i
      *ngIf="role.inherit"
      class="pi pi-angle-double-down"
      pTooltip="Vererbt"></i>
    <i
      *ngIf="role.default"
      class="pi pi-check-circle ml-2"
      pTooltip="Standardmäßig vergeben"></i>
  </div>
</ng-template>

<ng-template #information let-employee>
  <form #employeeForm="ngForm" class="formgrid grid">
    <div class="field col-12 md:col-6">
      <label for="firstname" class="block">Vorname</label>
      <input
        name="firstName"
        type="text"
        [(ngModel)]="employee.firstName"
        pInputText
        [required]="true"
        minlength="2"
        appForbiddenValue="tes"
        class="w-full" />
    </div>
    <div class="field col-12 md:col-6">
      <label for="lastname">Nachname</label>
      <input
        name="lastname"
        type="text"
        class="w-full"
        [(ngModel)]="employee.name"
        [required]="true"
        minlength="2"
        pInputText />
    </div>

    <div class="field col-12 md:col-6">
      <label for="phone">Telefonnummer</label>
      <input
        name="phone"
        type="tel"
        placeholder="+491701234567"
        class="inputfield w-full"
        [(ngModel)]="employee.phone"
        pInputText />
    </div>
    <div class="field col-12 md:col-6">
      <label for="email">E-Mail</label>
      <input
        name="email"
        type="email"
        email="true"
        size="30"
        class="inputfield w-full"
        [(ngModel)]="employee.email"
        [required]="true"
        minlength="2"
        pInputText />
    </div>
    <div class="field col-12 md:col-4">
      <label for="address">Straße</label>
      <input
        name="address"
        type="text"
        rows="4"
        [(ngModel)]="employee.street"
        class="w-full"
        [required]="true"
        minlength="2"
        pInputText />
    </div>

    <div class="field col-12 md:col-4">
      <label for="city">Stadt</label>
      <input
        name="city"
        type="text"
        class="w-full"
        [(ngModel)]="employee.city"
        [required]="true"
        minlength="2"
        pInputText />
    </div>

    <div class="field col-12 md:col-4">
      <label for="zip">PLZ</label>
      <input
        name="zip"
        type="text"
        class="w-full"
        [(ngModel)]="employee.postcode"
        [required]="true"
        minlength="4"
        pInputText />
    </div>
    <div class="field col-12 md:col-6">
      <label for="tax_number" class="block">Umsatzsteuer-ID</label>
      <input
        name="tax_number"
        type="text"
        [(ngModel)]="employee.tax_number"
        pInputText
        minlength="2"
        class="w-full" />
    </div>
  </form>
</ng-template>

<ng-template #bank let-employee>
  <div class="formgrid grid">
    <div class="field col-12 md:col-8">
      <label for="iban">IBAN</label>
      <input
        id="iban"
        type="text"
        [(ngModel)]="employee.iban"
        class="inputfield w-full"
        [required]="true"
        minlength="2"
        pInputText />
    </div>
    <div class="field col-12 md:col-4">
      <label for="bic">BIC</label>
      <input
        id="bic"
        type="text"
        [(ngModel)]="employee.bic"
        class="inputfield w-full"
        [required]="true"
        minlength="2"
        pInputText />
    </div>
  </div>
</ng-template>

<ng-template #broker let-employee>
  <div class="formgrid grid">
    <div
      class="field col-12 md:col-6"
      *ngFor="let c of referer_identifierOptions">
      <label for="id">{{ c }}</label>
      <input
        name="id"
        type="text"
        class="w-full"
        [(ngModel)]="employee.broker_identifier[c]"
        [placeholder]="getParentBroker(c)"
        [required]="true"
        minlength="2"
        pInputText />
    </div>
  </div>
</ng-template>
