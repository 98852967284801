import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import locale from '@angular/common/locales/de';
import { registerLocaleData } from '@angular/common';
import { registerLicense } from '@syncfusion/ej2-base';

registerLocaleData(locale);
registerLicense('Mgo+DSMBaFt+QHJqVk1hXk5Hd0BLVGpAblJ3T2ZQdVt5ZDU7a15RRnVfR11lSXlQf0ZlW31YcA==;Mgo+DSMBPh8sVXJ1S0R+X1pFdEBBXHxAd1p/VWJYdVt5flBPcDwsT3RfQF5jTH5VdkJgUHxYcnBVRg==;ORg4AjUWIQA/Gnt2VFhiQlJPd11dXmJWd1p/THNYflR1fV9DaUwxOX1dQl9gSXtTcEVkWnZddnZQQmA=;MTk0NDQzMEAzMjMxMmUzMjJlMzNra1JDVFFzSHdHTi9yZTNMUGV6dEhLUm5QQjZQZlIvNzgvbzlyS29jWkJNPQ==;MTk0NDQzMUAzMjMxMmUzMjJlMzNYZGJML29SdFdhNzVlRHMzemI1Sk9OOUREWWFQNHZZa3ZXTmljUnZPbkZVPQ==;NRAiBiAaIQQuGjN/V0d+Xk9HfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5WdkNjXnxXc3JWQmZc;MTk0NDQzM0AzMjMxMmUzMjJlMzNRTWVwQlZZdXAvRzAydUNiV2xNSnZhUiszZUVtOHk5NldxV3VNV3hVV3pBPQ==;MTk0NDQzNEAzMjMxMmUzMjJlMzNNanY5cVM1dlE0K2NCbytFa1ZzY282WWtUM2RuVmRvbzgvK0FkdEtRN29rPQ==;Mgo+DSMBMAY9C3t2VFhiQlJPd11dXmJWd1p/THNYflR1fV9DaUwxOX1dQl9gSXtTcEVkWnZddnBXQ2A=;MTk0NDQzNkAzMjMxMmUzMjJlMzNFWm9vSU9ISFJWc09kcmdsdS9wSTRhYkNjeGlSUjAxZHo2a3RZYkw1bTNnPQ==;MTk0NDQzN0AzMjMxMmUzMjJlMzNjSzFVcWl5NDYyNTY3cnlSblNtZTdMdzlseTBxU1lIcXRhTzRQR3k2eU1jPQ==;MTk0NDQzOEAzMjMxMmUzMjJlMzNRTWVwQlZZdXAvRzAydUNiV2xNSnZhUiszZUVtOHk5NldxV3VNV3hVV3pBPQ==');

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
