import { DatePipe } from '@angular/common';
import { Inject, LOCALE_ID } from '@angular/core';
import {
  Company,
  EmploymentType,
  Gender,
  IApplication,
  ICredentials,
  IInsurance,
  IPerson,
  IRole,
  ITerm,
  IUser,
  Permissions,
} from 'froitzheim-shared';
import { BaseDTO } from './base.dto';

export class User extends BaseDTO implements IUser {
  constructor(data?: IUser, @Inject(LOCALE_ID) private locale: string = 'de-DE') {
    super();
    Object.assign(this, data);
  }
  roles: IRole[] = [];
  user_id?: number;
  name?: string;
  firstName?: string;
  postcode?: string;
  city?: string;
  street?: string;
  streetNumber?: string;
  phone?: string;
  email?: string;
  iban?: string;
  bic?: string;
  employment_type?: EmploymentType;
  credentials?: ICredentials;
  vat?: string | undefined;
  tax_number?: string | undefined;
  applications?: IApplication[] | undefined;
  referal?: string | undefined;
  parent?: IUser | undefined;
  children: IUser[] = [];
  allowedInsurances: number[] = [];
  provision = 1;
  _total_provision?: number | undefined;
  broker_identifier: Record<Company, string> = <Record<Company, string>>{};
  broker_identifier_json?: string;
  get fullName(): string {
    return (this.firstName + ' ' + this.name).trim();
  }
}

export class Credential implements ICredentials {
  user?: IUser;
  hash?: string;
  password?: string;
  created_at: Date = new Date();
  updated_at: Date = new Date();

}